import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {LocalStorageService} from '../localStorage/local-storage.service';
import {environment} from '../../../../../environments/environment';
import {User} from '../../../../models/user';

@Injectable({
  providedIn: 'root'
})
export class UserStorageService {

  user: BehaviorSubject<User | null>;

  constructor(private localStorageService: LocalStorageService) {
    this.user = new BehaviorSubject<User | null>(null);
    if (this.getUser() !== null) {
      this.user.next(this.getUser());
    }
  }

  /**
   * Method called to save the user in localStorage
   * @param user
   */
  saveUser(user: User): void {
    this.localStorageService.saveData(environment.localStorageKeys.user, JSON.stringify(user.toJSON()));
    this.user.next(user);
  }

  /**
   * Method called to get the user stored in localStorage
   */
  private getUser(): User | null {
    const user = JSON.parse(this.localStorageService.getData(environment.localStorageKeys.user));
    return user ? User.fromJSON(user) : null;
  }

  /**
   * Method called to clear the user
   */
  clearUser(): void {
    this.localStorageService.removeData(environment.localStorageKeys.user);
    this.user.next(null);
  }

}
