import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Item, ItemHttpIn, ItemHttpOut} from '../../../../models/item';
import {QueryOptionsBuilder} from '../../../../models/queryOptions/builder';
import {QueryOptionsPopulate} from '../../../../models/queryOptions/populate';
import {BaseApi} from '../../../shared/services/base.api';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ItemApi extends BaseApi<Item, ItemHttpIn, ItemHttpOut> {

  constructor(http: HttpClient) {
    super(http);
    this.baseUrl = this.apiUrl + 'items/';
    this.sortField = 'name';
  }

  /**
   * @override
   * @param dataAsJSON
   */
  typeFromJSON(dataAsJSON: any): Item {
    return Item.fromItemHttpToItem(dataAsJSON);
  };

  /**
   * @override
   * @param entity
   */
  typeToJSON(entity: Item): ItemHttpOut {
    return Item.fromItemToItemHttp(entity);
  }

  findByIdWithPopulate(itemId: string): Promise<Item> {
    const queryOptions = new QueryOptionsBuilder();
    queryOptions.pushPopulate(new QueryOptionsPopulate('categoryId'))
    queryOptions.pushPopulate(new QueryOptionsPopulate('subCategoryId'))
    return this.findById(itemId, queryOptions);
  }

  findAllWithPopulate(): Promise<Item[]> {
    const queryOptions = new QueryOptionsBuilder();
    queryOptions.pushPopulate(new QueryOptionsPopulate('categoryId'))
    queryOptions.pushPopulate(new QueryOptionsPopulate('subCategoryId'))
    return this.findAll(queryOptions);
  }

  updateItemsCatIdBySubCatId(itemSubCategoryId: string, itemCategoryId: string): Promise<any> {
    return firstValueFrom(this.http
      .put<Item>(this.baseUrl + 'category-id/sub-category/' + itemSubCategoryId, {categoryId: itemCategoryId})
      )
  }
}
