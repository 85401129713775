import {Component, Input, OnChanges, OnInit, ViewChild} from '@angular/core';
import {BaseChartDirective} from 'ng2-charts';
import {ChartConfiguration, ChartOptions} from 'chart.js';

@Component({
  selector: 'app-generic-chart',
  templateUrl: './generic-chart.component.html',
  styleUrls: ['./generic-chart.component.scss']
})
export class GenericChartComponent implements OnInit, OnChanges {

  @ViewChild(BaseChartDirective) private baseChart!: BaseChartDirective;

  @Input() chartDataConfiguration!: ChartConfiguration<any>;
  @Input() heightCanvas!: number | undefined;
  @Input() widthCanvas!: number | undefined;

  chartColors: string[] = ['#E0CB0B', '#2C6DDD'];

  constructor() {
  }

  /**
   * Lifecycle Method
   */
  ngOnInit() {
    this.initChart();
  }

  /**
   * Lifecycle Method
   */
  ngOnChanges() {
    if(this.baseChart) {
      this.baseChart.update();
    }
  }

  /**
   * Method called for init the chart
   * @private
   */
  private initChart(): void {

    // If only one data, do not display the labels y1
    const y1Display = this.chartDataConfiguration.data.datasets.length > 1;

    const defaultConfig: ChartOptions = {
      responsive: true,
      elements: {
        bar: {
          hoverBackgroundColor: '#fff'
        }
      },
      borderColor: 'rgba(255, 255, 255, 0)',
      scales: {
        x: {
          grid: {
            display: true,
            color: 'rgba(255, 255, 255, 0)'
          },
          border: {
            color: '#fff',
            width: 1
          },
          ticks: {
            display: y1Display,
            color: '#FFF',
            font: {
              family: 'Poppins',
              weight: 'bold'
            }
          }
        },
        y0: {
          min: 0,
          grid: {
            display: true,
            color: 'rgba(255, 255, 255, 0)'
          },
          border: {
            color: '#fff',
            width: 1
          },
          position: 'left',
          ticks: {
            color: '#E0CB0B',
            font: {
              family: 'Poppins',
              weight: 'bold'
            }
          }
        },
        y1: {
          min: 0,
          position: 'right',
          grid: {
            display: true,
            color: 'rgba(255, 255, 255, 0)'
          },
          border: {
            color: '#fff',
            width: 1
          },
          ticks: {
            color: '#2C6DDD',
            font: {
              family: 'Poppins',
              weight: 'bold'
            }
          }
        },
      },
      plugins: {
        legend: {
          display: false
        }
      }
    };

    // If options does not exist, set the options by default, otherwise, sort the options case by case
    this.chartDataConfiguration.options = {...defaultConfig, ...this.chartDataConfiguration.options};
  }

}
