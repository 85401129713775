import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {environment} from '../../../../../environments/environment';
import {LocalStorageService} from '../localStorage/local-storage.service';

@Injectable({
  providedIn: 'root'
})
export class TokenStorageService {

  token: BehaviorSubject<string>;

  constructor(private localStorageService: LocalStorageService) {
    this.token = new BehaviorSubject<string>('');
    if (this.getToken() !== null) {
      this.token.next(this.getToken());
    }
  }

  /**
   * Method called to save the token in localStorage
   * @param token
   */
  saveToken(token: string): void {
    this.localStorageService.saveData(environment.localStorageKeys.token, token);
    this.token.next(token);
  }

  /**
   * Method called to get the token stored in localStorage
   */
  private getToken(): string {
    return this.localStorageService.getData(environment.localStorageKeys.token);
  }

  /**
   * Method called to clear the token stored in localStorage
   */
  clearToken(): void {
    this.localStorageService.removeData(environment.localStorageKeys.token);
    this.token.next('');
  }
}
