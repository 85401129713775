import {User} from './user';

export class Comment {

  private _id: string | undefined;
  private _content: string;
  private _userId: string | User;
  private _groupId: string;
  private _newsId: string;
  private _createdAt: string;
  private _updatedAt: string;

  constructor(content: string, userId: string | User, groupId: string, newsId: string, createdAt: string, updatedAt: string, id?: string) {
    if (typeof id === 'string') {
      this._id = id;
    }
    this._content = content;
    this._userId = userId;
    this._groupId = groupId;
    this._newsId = newsId;
    this._createdAt = createdAt;
    this._updatedAt = updatedAt;
  }

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get content(): string {
    return this._content;
  }

  set content(value: string) {
    this._content = value;
  }

  get userId(): string | User {
    return this._userId;
  }

  set userId(value: string | User) {
    this._userId = value;
  }

  get groupId(): string {
    return this._groupId;
  }

  set groupId(value: string) {
    this._groupId = value;
  }

  get newsId(): string {
    return this._newsId;
  }

  set newsId(value: string) {
    this._newsId = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  /**
   * Method to transform the class to json for the API
   */
  toJSON(): any {
    return {
      content: this.content,
      userId: typeof this.userId === 'string' ? this.userId : this.userId.id,
      groupId: this.groupId,
      newsId: this.newsId,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      _id: this.id
    };
  }

  /**
   * Method to transform data from the API into class
   */
  static fromJSON(dataAsJSON: any): Comment {
    return new Comment(
      dataAsJSON.content,
      typeof dataAsJSON.userId === 'string' ? dataAsJSON.userId : User.fromJSON(dataAsJSON.userId),
      dataAsJSON.groupId,
      dataAsJSON.newsId,
      dataAsJSON.createdAt,
      dataAsJSON.updatedAt,
      dataAsJSON._id
    );
  }

}
