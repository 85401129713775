<lib-toastify-toast-container [hideProgressBar]="true" [autoClose]="2500"></lib-toastify-toast-container>

<ngx-spinner bdColor="rgba(0, 0, 0, 0.8)" type="ball-scale-multiple"></ngx-spinner>

<div class="container-fluid component app">

  <div *ngIf="isMobile && token && topBarInfos" class="row d-flex align-items-center header-top">
    <div class="col d-inline-flex justify-content-start">
      <div class="mx-2" (click)="menuCollapse.toggle()" [attr.aria-expanded]="!isCollapsed">
        <img id="burger-icon" src="assets/img/menu-icons/burger.svg" alt="menu">
      </div>
      <div class="text-truncate">
        {{topBarInfos.label}}
      </div>
      <div *ngIf="topBarInfos.icon" class="fast-prono-closing">
        <img src="assets/img/menu-icons/{{topBarInfos.icon}}.svg" alt="topBarIcon"
             [routerLink]="topBarInfos.url ? [topBarInfos.url] : []"
             [ngClass]="{'cursor-pointer' : topBarInfos.url}" class="top-bar-action">
      </div>
    </div>
  </div>

  <div class="row">
    <div class="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-4 fixed side-menu"
         [ngClass]="{'d-none' : !token}"
         (ngbCollapseChange)="onChangeNgbCollapse()"
         [ngStyle]="isMobile ? {'z-index': 9} : {}"
         [ngbCollapse]="isMobile && isCollapsed"
         #menuCollapse="ngbCollapse"
         [animation]="false">
      <app-side-menu *ngIf="token"></app-side-menu>
    </div>

    <div *ngIf="!isMobile && token" class="col-xxl-2 col-xl-3 col-lg-3 col-md-4 col-sm-4">
    </div>

    <div [ngClass]="!token ? 'col-12 py-5' : 'col-12 col-xxl-10 col-xl-9 col-lg-9 col-md-8 col-sm-12 main-perso'"
         (click)="collapseMenu()">
      <router-outlet></router-outlet>
    </div>
  </div>

</div>
