import {Injectable} from '@angular/core';
import {
  ItemUpdatedNotification,
  NewItemNotification,
  RefreshAllItemNotification
} from '../../../../models/notification/item.notification';
import {Notification} from '../../../../models/notification/notification';
import {ItemService} from '../../../shop/store/item/item.service';

@Injectable({providedIn: 'root'})
export class ItemNotificationObserver implements Notification.Observer<NewItemNotification | ItemUpdatedNotification | RefreshAllItemNotification> {
  constructor(private itemService: ItemService) {
  }

  async observe(notification: Notification<NewItemNotification | ItemUpdatedNotification | RefreshAllItemNotification>): Promise<void> {
    if (notification.code === Notification.Code.NEW_ITEM || notification.code === Notification.Code.ITEM_UPDATED) {
      this.itemService.fetchById((notification as unknown as Notification<NewItemNotification | ItemUpdatedNotification>).body?.itemId!);
    }

    if (notification.code === Notification.Code.REFRESH_ALL_ITEM) {
      this.itemService.fetchAll()
    }
  }

  shouldObserve(notification: Notification<any>): boolean {
    return notification.code === Notification.Code.NEW_ITEM
      || notification.code === Notification.Code.ITEM_UPDATED
      || notification.code === Notification.Code.REFRESH_ALL_ITEM;
  }
}
