import { Injectable } from '@angular/core';
import { ItemCategoryStore } from './item-category.store';
import {ItemCategoryApi} from '../../api/itemCategory/item-category.api';
import {ItemCategory} from '../../../../models/itemCategory';
import {ToastService} from 'angular-toastify';

@Injectable({ providedIn: 'root' })
export class ItemCategoryService {

  constructor(private itemCategoryStore: ItemCategoryStore, private itemCategoryApi: ItemCategoryApi,
              private toastService: ToastService) {
  }

  async fetchById(itemCategoryId: string): Promise<void> {
    const item = await this.itemCategoryApi.findByIdWithPopulate(itemCategoryId);
    this.itemCategoryStore.upsert(item.id!, item);
  }

  async fetchAll(): Promise<void> {
    const itemCategories = await this.itemCategoryApi.findAllWithPopulate()
    this.itemCategoryStore.upsertMany(itemCategories)
  }

  async save(itemCategoryToSave: ItemCategory): Promise<void> {
    try {
      await this.itemCategoryApi.save(itemCategoryToSave);
      this.toastService.success('Catégorie ajoutée.');
    } catch (err) {
      console.error(err);
      this.toastService.error('Une erreur s\'est produite.');
    }
  }

  async update(itemCategoryEdited: ItemCategory): Promise<void> {
    try {
      await this.itemCategoryApi.update(itemCategoryEdited);
      this.toastService.success('Catégorie mise à jour.');
    } catch (err) {
      console.error(err);
      this.toastService.error('Une erreur s\'est produite.');
    }
  }

  async updatePut(itemCategoryEdited: ItemCategory): Promise<void> {
    try {
      await this.itemCategoryApi.updatePut(itemCategoryEdited);
      this.toastService.success('Catégorie mise à jour.');
    } catch (err) {
      console.error(err);
      this.toastService.error('Une erreur s\'est produite.');
    }
  }

}
