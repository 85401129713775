// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  api: {
    remote: 'https://www.pronostars-api-dev.wearechev.fr/api/v2/',
    local: 'http://127.0.0.1:8181/api/v2/',
    isRemote: true
  },
  resourcesServer: 'https://www.pronostars-images.wearechev.fr',
  localStorageKeys: {
    token: 'auth-token',
    user: 'current-user'
  },
  rapidApiKey: 'f92071b8aamsh41cba93c6515691p18e45fjsnfd6eb9a93702'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
