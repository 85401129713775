import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {TokenStorageService} from '../../../shared/services/tokenStorage/token-storage.service';
import {UserStorageService} from '../../../shared/services/userStorage/user-storage.service';
import {map, tap} from 'rxjs/operators';
import {User} from '../../../../models/user';
import {environment} from '../../../../../environments/environment';
import {StreamEventHandlerService} from '../../../shared/services/stream-event-handler/stream-event-handler.service';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  apiUrl: string;
  baseUrl!: string;

  constructor(private http: HttpClient, private tokenStorageService: TokenStorageService,
              private userStorageService: UserStorageService, private streamEventHandlerService: StreamEventHandlerService) {
    if (environment.api.isRemote) {
      this.apiUrl = environment.api.remote;
    } else {
      this.apiUrl = environment.api.local;
    }

    this.baseUrl = this.apiUrl + 'auth/';
  }

  /**
   * Method called for try to login a user
   * @param credentials
   */
  login(credentials: any): Promise<void | any> {

    if (credentials.username !== 'Tony' && credentials.username !== 'Mindya') {
      return Promise.reject({
        errorMsg: 'Un problème est survenu, merci de contacter un administrateur.'
      });
    }

    return firstValueFrom(this.http
      .post(this.baseUrl + 'login', credentials)
      .pipe(
        map((dataAsJson: any) => ({
          token: dataAsJson.token,
          user: User.fromJSON(dataAsJson.user)
        })),
        tap((data: { token: string, user: User }) => this.tokenStorageService.saveToken(data.token)),
        tap((data: { token: string, user: User }) => this.userStorageService.saveUser(data.user)),
        tap(() => this.streamEventHandlerService.initStreamEventHandler())
      )
      )
  }


  /**
   * Method called for logout the user
   */
  logout(): Promise<void> {
    return new Promise<void>(
      (res, rej) => {
        this.tokenStorageService.clearToken();
        this.userStorageService.clearUser();
        this.streamEventHandlerService.closeConnection();
        res();
      }
    );
  }

  /**
   * Method called to check the validator of the token with the API
   */
  checkToken(): Promise<any> {
    return firstValueFrom(this.http
      .get(this.baseUrl + 'check-token')
      )
  }

  /**
   * Method called to renew the token
   */
  refreshToken(): Promise<any> {
    return firstValueFrom(this.http
      .post(this.baseUrl + 'refresh-token', {})
      )
  }
}
