import {Inject, Injectable} from '@angular/core';
import {NotificationObserverInjectionToken} from '../../../../injection.token';
import {Notification} from '../../../../models/notification/notification';

@Injectable({
  providedIn: 'root'
})
export class ObserveNotificationService {

  constructor(@Inject(NotificationObserverInjectionToken) private notificationObservers: Notification.Observer<any>[]) {
  }

  observe(notification: any): void {
    const notificationObservers = this.notificationObservers.filter((observer) => observer.shouldObserve(notification))
    if (notificationObservers && notificationObservers.length > 0) {
      notificationObservers.forEach(notificationObserver => notificationObserver.observe(notification))
    }
  }
}
