<div class="m-0 container comments-list">

  <button (click)="activeModal.dismiss('Cross click')" class="btn-close-popup">
    <img class="btn-icon" src="assets/img/menu-icons/close.svg" alt="close">
  </button>

  <div class="row my-3 page-header">
    <div class="d-none d-md-flex">
      <img class="mt-1 me-3 icons" src="assets/img/menu-icons/add.svg" alt="dashboard">
      <h1 class="m-0">Liste des commentaires</h1>
    </div>
  </div>

    <div infiniteScroll
         [infiniteScrollDistance]="2"
         [infiniteScrollThrottle]="50"
         [infiniteScrollContainer]="'.comments-list'"
         [fromRoot]="true"
         (scrolled)="onScroll()">

        <div class="d-flex d-none-mobile p-3 content-header" id="modal-head-table">
          <div class="col-2">Date</div>
          <div class="col-3">Utilisateur</div>
          <div class="col-3">Contenu</div>
          <div class="col-2">ID</div>
          <div class="col-2">Action</div>
        </div>

        <div class="content-row d-flex align-items-start p-3 mt-1 body-table" id="modal-body-table"
             *ngFor="let comment of comments; index as i">
          <div class="col-2">
            Le {{comment.createdAt | date:'dd/MM/yyyy HH:mm'}}
          </div>
          <div class="col-3">
            {{comment.userId.username}}
          </div>
          <div class="col-3">
            {{comment.content}}
          </div>
          <div class="col-2 text-break opacity-75">
            {{comment.id}}
          </div>
          <div class="col-2">
            <button type="button" class="btn btn-outline-light" (click)="onClickOpenModalConfirmDelete(i)">
              Suppr.
            </button>
          </div>
        </div>

    </div>

</div>

