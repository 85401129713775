import {Injectable} from '@angular/core';
import {BaseService} from '../base.service';
import {HttpClient, HttpParams} from '@angular/common/http';
import {Comment} from '../../../../models/comment';
import {QueryOptionsFilter} from '../../../../models/queryOptions/filter';
import {QueryOptionsBuilder} from '../../../../models/queryOptions/builder';
import {QueryOptionsPopulate} from '../../../../models/queryOptions/populate';
import {QueryOptionsPage} from '../../../../models/queryOptions/page';
import {QueryOptionsLimit} from '../../../../models/queryOptions/limit';
import {Log} from '../../../../models/log';
import {firstValueFrom} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommentService extends BaseService<Comment> {

  constructor(http: HttpClient) {
    super(http);
    this.baseUrl = this.apiUrl + 'comments/';
    this.sortField = '-createdAt';
  }

  /**
   * @override
   * @param dataAsJSON
   */
  typeFromJSON(dataAsJSON: any): Comment {
    return Comment.fromJSON(dataAsJSON);
  };

  /**
   * Method called to get the comments by giving a pronoId | groupId | newsId
   * @param searchId
   * @param page
   * @param limit
   */
  findAllByFilters(searchId: string, page: number, limit: number = 20): Promise<Comment[]> {

    const filterOne = new QueryOptionsFilter(searchId, 'pronoId');
    const filterTwo = new QueryOptionsFilter(searchId, 'groupId');
    const filterThree = new QueryOptionsFilter(searchId, 'newsId');

    const filterComplex = new QueryOptionsFilter(undefined, undefined, undefined, true, undefined, 'or', [filterOne, filterTwo, filterThree]);

    const nestedPopulate = new QueryOptionsPopulate('currentLevel');
    const populate = new QueryOptionsPopulate('userId', undefined, nestedPopulate);

    const queryOptions = new QueryOptionsBuilder();
    queryOptions.pushFilter(filterComplex);
    queryOptions.pushPopulate(populate);
    queryOptions.page = new QueryOptionsPage(page);
    queryOptions.limit = new QueryOptionsLimit(limit);

    return this.findAll(queryOptions);
  }

  /**
   * Method called for delete all comments that match the given filter
   * @param searchId
   */
  deleteAllCommentsByFilter(searchId: string): Promise<any> {

    const filterOne = new QueryOptionsFilter(searchId, 'pronoId');
    const filterTwo = new QueryOptionsFilter(searchId, 'groupId');
    const filterThree = new QueryOptionsFilter(searchId, 'newsId');

    const filterComplex = new QueryOptionsFilter(undefined, undefined, undefined, true, undefined, 'or', [filterOne, filterTwo, filterThree]);

    const queryOptions = new QueryOptionsBuilder();
    queryOptions.pushFilter(filterComplex);

    return this.deleteMany(queryOptions);
  }

  /**
   * Method called to get the number of comment by giving a pronoId | groupId | newsId
   * @param searchId
   */
  countComments(searchId: string): Promise<number> {

    const filterOne = new QueryOptionsFilter(searchId, 'pronoId');
    const filterTwo = new QueryOptionsFilter(searchId, 'groupId');
    const filterThree = new QueryOptionsFilter(searchId, 'newsId');

    const filterComplex = new QueryOptionsFilter(undefined, undefined, undefined, true, undefined, 'or', [filterOne, filterTwo, filterThree]);

    const queryOptions = new QueryOptionsBuilder();
    queryOptions.pushFilter(filterComplex);

    return this.countBy(queryOptions);
  }

  /**
   * Method to return all logs of comment
   * @param dateStart
   * @param dateEnd
   * @param options
   * @param page
   * @param limit
   */
  findAllLatestLogs(dateStart: Date, dateEnd: Date, options: { label: string, isActivated: boolean }[], page: number, limit: number = 30): Promise<any[]> {

    const queryOptions = new QueryOptionsBuilder();

    queryOptions.limit = new QueryOptionsLimit(limit);
    queryOptions.page = new QueryOptionsPage(page);

    const optionsFilter = options.reduce((acc: QueryOptionsFilter[], option) => {
      if(option.isActivated) {
        const optionFilter = new QueryOptionsFilter(undefined, undefined, undefined, undefined,
          undefined, 'exists', [
            new QueryOptionsFilter(true)]);
        acc.push(new QueryOptionsFilter(optionFilter, option.label));
      }
      return acc;
    }, []);

    queryOptions.pushFilter(new QueryOptionsFilter(undefined, undefined, undefined, undefined,
      undefined, 'or', optionsFilter));

    queryOptions.pushFilter(QueryOptionsFilter.generateRangeFilter(dateStart, dateEnd, 'createdAt', true));

    const params = new HttpParams()
      .append('options', queryOptions.toJSON());

    return firstValueFrom(this.http
      .get<Log[]>(this.baseUrl + 'logs', {params})
      )
  }
}
