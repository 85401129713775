import {Injectable} from '@angular/core';
import {QueryEntity} from '@datorama/akita';
import {ItemState, ItemStore} from './item.store';
import {BehaviorSubject, combineLatest, Observable} from 'rxjs';
import {Item} from '../../../../models/item';
import {map} from 'rxjs/operators';

interface QueryFilters {
  searchTerms?: string;
  categoryId?: string;
  subCategoryId?: string;
}

@Injectable({providedIn: 'root'})
export class ItemQuery extends QueryEntity<ItemState> {

  filters!: BehaviorSubject<QueryFilters>;

  constructor(protected store: ItemStore) {
    super(store);
    this.filters = new BehaviorSubject<QueryFilters>({});
  }

  selectAllByFiltersWithImagePath(): Observable<Item[]> {
    return combineLatest([
      this.selectAll(),
      this.filters
    ])
      .pipe(
        map(([items, filters]) => (
          items
            .filter(item => (this.checkFilters(item, filters)))
            .map(item => ({...item, fullImgPath: Item.fullImgPath(item)})))),
        map(items => items.sort((itemA: Item, itemB: Item) => itemB.createdAt.getDate() - itemA.createdAt.getDate()))
      );
  }

  private checkFilters(item: Item, filters: QueryFilters): boolean {
    if (!Object.keys(filters).length) return true;

    const isAAndFilter = !!((filters.categoryId && filters.subCategoryId) || (filters.subCategoryId && filters.searchTerms) || (filters.categoryId && filters.searchTerms) || (filters.categoryId && filters.subCategoryId && filters.searchTerms));
    if (!isAAndFilter) return this.checkSearchTermsFilter(item, filters) || this.checkSubCategoryIdFilter(item, filters) || this.checkSearchTermsFilter(item, filters);

    if (filters.categoryId && filters.subCategoryId) {
      return this.checkCategoryIdFilter(item, filters) && this.checkSubCategoryIdFilter(item, filters);
    }

    if (filters.categoryId && filters.searchTerms) {
      return this.checkCategoryIdFilter(item, filters) && this.checkSearchTermsFilter(item, filters);
    }

    if (filters.subCategoryId && filters.searchTerms) {
      return this.checkSubCategoryIdFilter(item, filters) && this.checkSearchTermsFilter(item, filters);
    }

    if (filters.categoryId && filters.subCategoryId && filters.searchTerms) {
      return this.checkSearchTermsFilter(item, filters) && this.checkSubCategoryIdFilter(item, filters) && this.checkSearchTermsFilter(item, filters);
    }

    return true;
  }

  private checkSearchTermsFilter(item: Item, filters: QueryFilters): boolean {
    return !!(filters.searchTerms && item.name.includes(filters.searchTerms));
  }

  private checkCategoryIdFilter(item: Item, filters: QueryFilters): boolean {
    return !!(filters.categoryId && item.category.id === filters.categoryId);
  }

  private checkSubCategoryIdFilter(item: Item, filters: QueryFilters): boolean {
    return !!(filters.subCategoryId && item.subCategory && item.subCategory.id === filters.subCategoryId);
  }

}
