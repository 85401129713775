<div class="container-fluid m-0 p-0 side-menu">

  <div class="row categories">
    <div id="dashboard" class="col-12 mx-3 d-flex align-items-center justify-content-left dashboard" routerLink="/"
         routerLinkActive="active-link" [routerLinkActiveOptions]="{exact: true}">
      <img class="h-50" src="assets/img/menu-icons/dashboard.svg" alt="dashboard">
      <div class="mx-3">Dashboard</div>
    </div>
  </div>

  <div class="row categories">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/users.svg" alt="users">
      <div class="mx-3 categorie-title">Utilisateurs</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/users" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/list.svg" alt="list">
          </div>
          <div class="mx-3">Liste</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/groups" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/groups.svg" alt="groups">
          </div>
          <div class="mx-3">Groupes</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/logs" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn menu-btn-pending">
            <img src="assets/img/menu-icons/historic.svg" alt="historic">
          </div>
          <div class="mx-3">Historique</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row categories py-3">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/matches.svg" alt="matches">
      <div class="mx-3 categorie-title">Matchs</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/pronostics/new" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn menu-btn-add">
            <img src="assets/img/menu-icons/add.svg" alt="add">
          </div>
          <div class="mx-3">Ajouter</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/pronostics/close" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn menu-btn-close">
            <img src="assets/img/menu-icons/close.svg" alt="play">
          </div>
          <div class="mx-3">Clôturer</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/pronostics/inProgress" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn menu-btn-pending">
            <img src="assets/img/menu-icons/play.svg" alt="play">
          </div>
          <div class="mx-3">En cours</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/pronostics/history" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/historic.svg" alt="historic">
          </div>
          <div class="mx-3">Historique</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row categories py-3">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/news.svg" alt="new">
      <div class="mx-3 categorie-title">Actualités</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/news/new" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn menu-btn-add">
            <img src="assets/img/menu-icons/add.svg" alt="add">
          </div>
          <div class="mx-3">Ajouter</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/news" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/historic.svg" alt="historic">
          </div>
          <div class="mx-3">Historique</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row categories py-3">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/shop.svg" alt="shop">
      <div class="mx-3 categorie-title">Boutique</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/shop/new" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn menu-btn-add">
            <img src="assets/img/menu-icons/add.svg" alt="add">
          </div>
          <div class="mx-3">Ajouter</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/shop" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/list.svg" alt="list">
          </div>
          <div class="mx-3">Liste</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/shop/categories" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/categories.svg" alt="categories">
          </div>
          <div class="mx-3">Catégories</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row categories py-3">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/rankings.svg" alt="rankings">
      <div class="mx-3 categorie-title">Classements</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/global-rankings/export-for-competition" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/sports.svg" alt="xp">
          </div>
          <div class="mx-3">Compét. & Exports</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/global-rankings/xp" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/xp.svg" alt="xp">
          </div>
          <div class="mx-3">XP & LVL</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/global-rankings/free-money" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/coins.svg" alt="xp">
          </div>
          <div class="mx-3">Coins</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/global-rankings/points" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/points.svg" alt="xp">
          </div>
          <div class="mx-3">Points</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/global-rankings/total-prono" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/pronos.svg" alt="xp">
          </div>
          <div class="mx-3">Pronos</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/global-rankings/total-good-prono" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/pronos-good.svg" alt="xp">
          </div>
          <div class="mx-3">Bons pronos</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/global-rankings/total-bad-prono" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/pronos-bad.svg" alt="xp">
          </div>
          <div class="mx-3">Mauvais pronos</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row categories py-3">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/sports.svg" alt="sports">
      <div class="mx-3 categorie-title">Sports et Compet.</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/teams" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/teams.svg" alt="teams">
          </div>
          <div class="mx-3">Equipes</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/sports" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/sports.svg" alt="sports">
          </div>
          <div class="mx-3">Sports</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/competitions" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/competitions.svg" alt="competitions">
          </div>
          <div class="mx-3 text-truncate">Compétitions</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/seasons" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/seasons.svg" alt="seasons">
          </div>
          <div class="mx-3">Saisons</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/days" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/round.svg" alt="round">
          </div>
          <div class="mx-3">Journées</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/odds" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/cotes.svg" alt="cotes">
          </div>
          <div class="mx-3">Côtes</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row categories py-3">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/help.svg" alt="faq">
      <div class="mx-3 categorie-title">FAQ</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/faq/new" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn menu-btn-add">
            <img src="assets/img/menu-icons/add.svg" alt="add">
          </div>
          <div class="mx-3">Ajouter</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/faq" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/list.svg" alt="list">
          </div>
          <div class="mx-3">Liste</div>
        </li>
      </ul>
    </div>
  </div>

  <div class="row categories py-3">
    <div class="col-12 mx-3 d-flex align-items-center justify-content-left">
      <img class="h-50" src="assets/img/menu-icons/settings.svg" alt="settings">
      <div class="mx-3 categorie-title">Paramètres</div>
    </div>
    <div class="col-12 px-4">
      <ul>
        <li class="py-2 d-flex align-items-center" routerLink="/settings/experience" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/xp.svg" alt="xp">
          </div>
          <div class="mx-3">Expérience</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/settings/money" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/dollars.svg" alt="monnaie">
          </div>
          <div class="mx-3">Monnaie</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/settings/levels" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/levels.svg" alt="levels">
          </div>
          <div class="mx-3">Niveaux</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/settings/infos" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/informations.svg" alt="infos">
          </div>
          <div class="mx-3">Informations</div>
        </li>
        <li class="py-2 d-flex align-items-center" routerLink="/tools" routerLinkActive="active-link"
            [routerLinkActiveOptions]="{exact: true}">
          <div class="menu-btn">
            <img src="assets/img/menu-icons/check.svg" alt="check">
          </div>
          <div class="mx-3">Outils & Correctifs</div>
        </li>
        <li class="py-2 d-flex align-items-center" (click)="logout()">
          <div class="menu-btn">
            <img id="logout-icon" class="logout-icon" src="assets/img/menu-icons/logout.svg" alt="logout">
          </div>
          <div class="mx-3 text-nowrap">Déconnexion</div>
        </li>
      </ul>
    </div>
  </div>
</div>
