import {QueryOptionsPopulate} from './populate';
import {QueryOptionsSelect} from './select';
import {QueryOptionsSort} from './sort';
import {QueryOptionsFilter} from './filter';
import {QueryOptionsPage} from './page';
import {QueryOptionsLimit} from './limit';

interface IToJson {
  filter: any[] | undefined;
  populate: any[] | undefined;
  sort: any[] | undefined;
  select: any[] | undefined;
  page: number | undefined;
  limit: number | undefined;
}

export class QueryOptionsBuilder {

  private _filter: QueryOptionsFilter[];
  private _select: QueryOptionsSelect[];
  private _sort: QueryOptionsSort[];
  private _populate: QueryOptionsPopulate[];
  private _page: QueryOptionsPage | undefined;
  private _limit: QueryOptionsLimit | undefined;

  constructor() {
    this._filter = [];
    this._select = [];
    this._sort = [];
    this._populate = [];
    this._page = undefined;
    this._limit = undefined;
  }

  get filter(): QueryOptionsFilter[] {
    return this._filter;
  }

  set filter(value: QueryOptionsFilter[]) {
    this._filter = value;
  }

  get select(): QueryOptionsSelect[] {
    return this._select;
  }

  set select(value: QueryOptionsSelect[]) {
    this._select = value;
  }

  get sort(): QueryOptionsSort[] {
    return this._sort;
  }

  set sort(value: QueryOptionsSort[]) {
    this._sort = value;
  }

  get populate(): QueryOptionsPopulate[] {
    return this._populate;
  }

  set populate(value: QueryOptionsPopulate[]) {
    this._populate = value;
  }

  get page(): QueryOptionsPage | undefined {
    return this._page;
  }

  set page(value: QueryOptionsPage | undefined) {
    this._page = value;
  }

  get limit(): QueryOptionsLimit | undefined {
    return this._limit;
  }

  set limit(value: QueryOptionsLimit | undefined) {
    this._limit = value;
  }

  /**
   * Method called for push a new filter into the array
   * @param value
   */
  pushFilter(value: QueryOptionsFilter): void {
    this._filter.push(value);
  }

  /**
   * Method called for push a new select into the array
   * @param value
   */
  pushSelect(value: QueryOptionsSelect): void {
    this._select.push(value);
  }

  /**
   * Method called for push a new populate into the array
   * @param value
   */
  pushPopulate(value: QueryOptionsPopulate): void {
    this._populate.push(value);
  }

  /**
   * Method called for push a new sort into the array
   * @param value
   */
  pushSort(value: QueryOptionsSort): void {
    this._sort.push(value);
  }

  /**
   * Method called for transform the object instance to a JSON object
   */
  toJSON(): any {
    const toJSON: IToJson = {
      filter: undefined,
      populate: undefined,
      sort:  undefined,
      select: undefined,
      page: undefined,
      limit: undefined
    };

    if (this.filter.length > 0) {
      toJSON.filter = this.filter.map(f => f.toJSON());
    }

    if (this.populate.length > 0) {
      toJSON.populate = this.populate.map(f => f.toJSON());
    }

    if (this.sort.length > 0) {
      toJSON.sort = this.sort.map(f => f.toJSON());
    }

    if (this.select.length > 0) {
      toJSON.select = this.select.map(f => f.toJSON());
    }

    if (typeof this.page === 'object') {
      toJSON.page = this.page.toJSON();
    }

    if (typeof this.limit === 'object') {
      toJSON.limit = this.limit.toJSON();
    }

    return JSON.stringify(toJSON);
  }

}
