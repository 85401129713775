import {Injectable} from '@angular/core';
import {EntityState, EntityStore, StoreConfig} from '@datorama/akita';
import {ItemCategory} from '../../../../models/itemCategory';

export interface ItemCategoryState extends EntityState<ItemCategory, string> {
}

@Injectable({providedIn: 'root'})
@StoreConfig({name: 'itemCategory'})
export class ItemCategoryStore extends EntityStore<ItemCategoryState> {

  constructor() {
    super();
  }

}
