  <div class="mx-auto center-screen form-login">

    <div class="page-content">

      <div class="row h-100">

        <div class="col-auto mx-auto container center-screen mt-5">

          <div class="row mb-3">
            <div class="col d-flex justify-content-center">
              <div class="error" *ngIf="this.error">{{ this.error }}</div>
              <h1 class="form-text">Connexion</h1>
            </div>
          </div>

          <div class="row form-content">
            <div class="col-12">
              <form (ngSubmit)="onClickLoginBtn()" [formGroup]="loginForm">

                <div class="row justify-content-center mb-3">
                  <div class="col-6">
                    <input class="form-control" type="text" formControlName="username" placeholder="Nom d'utilisateur ou adresse email" >
                  </div>
                  <app-form-errors [group]="loginForm" class="input-error" controlName="username"
                                     controlLabel="Nom d'utilisateur"></app-form-errors>
                </div>

                <div class="row justify-content-center mb-3">
                  <div class="col-6">
                    <input class="form-control" type="password" formControlName="password" placeholder="Mot de passe" >
                  </div>
                  <app-form-errors [group]="loginForm" class="input-error" controlName="password"
                                     controlLabel="Mot de passe"></app-form-errors>
                </div>

                <div class="row">
                  <div class="col">
                    <button class="btn btn-primary" type="submit" [disabled]="loginForm.invalid">Se connecter</button>
                  </div>
                </div>

              </form>
            </div>
          </div>
        </div>

      </div>


    </div>

  </div>
