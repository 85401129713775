import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {TokenStorageService} from './modules/shared/services/tokenStorage/token-storage.service';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {AuthService} from './modules/authentication/services/auth/auth.service';
import {UserStorageService} from './modules/shared/services/userStorage/user-storage.service';
import {ToastService} from 'angular-toastify';
import {User} from './models/user';
import {TopBarInfo} from "./models/topBarInfo";
import {StreamEventHandlerService} from './modules/shared/services/stream-event-handler/stream-event-handler.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {

  routerEventsSub!: Subscription;
  tokenSub!: Subscription;
  userSub!: Subscription;

  isMobile!: boolean;
  isCollapsed!: boolean;

  token!: string;
  user!: User;

  topBarInfosMap!: Map<string, TopBarInfo>;

  topBarInfos!: TopBarInfo | undefined;

  constructor(private tokenStorageService: TokenStorageService, private router: Router,
              private authService: AuthService, private userStorageService: UserStorageService,
              private toastService: ToastService, private streamEventHandlerService: StreamEventHandlerService) {
    this.validTokenBeforeStartApp();
  }

  /**
   * Lifecycle Method
   */
  ngOnInit(): void {
    this.initSubs();
    this.initMaps();
    this.detectScreenSize(window.outerWidth);
  }

  /**
   * Method called to switch the collapsing of the side menu
   */
  onChangeNgbCollapse() {
    this.isCollapsed = !this.isCollapsed;
  }

  /**
   * Method called for collapse the menu when necessary
   */
  collapseMenu(): void {
    if (this.isMobile && !this.isCollapsed) {
      this.isCollapsed = !this.isCollapsed;
    }
  }

  /**
   * Lifecycle Method
   */
  ngOnDestroy() {
    this.routerEventsSub.unsubscribe();
    this.tokenSub.unsubscribe();
    this.userSub.unsubscribe();
  }

  /**
   * Method called for init all the subs
   * @private
   */
  private initSubs(): void {
    // Listening to the url change to call the setTopBar() method and change title and icon.
    this.routerEventsSub = this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.setTopBarInfos('/');
      }
      if (event instanceof NavigationEnd) {
        let url = event.url;
        // Remove the params on edit route
        if(url.includes('/edit/')) {
          const tmp = url.split('/');
          tmp.pop();
          url = tmp.join('/');
        }
        this.setTopBarInfos(url);
        this.collapseMenu();
      }
    });

    this.tokenSub = this.tokenStorageService.token.subscribe(token => this.token = token);
    this.userSub = this.userStorageService.user.subscribe(user => this.user = user!);
  }

  /**
   * Method called for initializing two Map arrays pairing with the url to retrieve the appropriate SVG icon and the french translation of each categories
   * @private
   */
  private initMaps(): void {
    this.topBarInfosMap = new Map([
      ['/', new TopBarInfo('Dashboard', '/pronostics/close', 'check')],
      ['/users', new TopBarInfo('Liste des utilisateurs')],
      ['/groups', new TopBarInfo('Groupes des utilisateurs')],
      ['/logs', new TopBarInfo('Historique des utilisateurs')],
      ['/pronostics/new', new TopBarInfo('Ajouter de nouveaux matchs')],
      ['/pronostics/assistedAdd', new TopBarInfo('Ajouter de nouveaux matchs')],
      ['/pronostics/close', new TopBarInfo('Clôturer un match')],
      ['/pronostics/inProgress', new TopBarInfo('Matchs en cours')],
      ['/pronostics/history', new TopBarInfo('Historique des matchs')],
      ['/pronostics/edit', new TopBarInfo('Edition du pronostic')],
      ['/news/new', new TopBarInfo('Ajouter une actualité')],
      ['/news/edit', new TopBarInfo('Modification d\'une actualité')],
      ['/news', new TopBarInfo('Historique des actualités')],
      ['/shop/new', new TopBarInfo('Ajouter un article')],
      ['/shop', new TopBarInfo('Liste des articles')],
      ['/shop/categories', new TopBarInfo('Catégories des articles')],
      ['/global-rankings/export-for-competition', new TopBarInfo('Classements par compétitions + Export CSV')],
      ['/global-rankings/xp', new TopBarInfo('Classements par XP')],
      ['/global-rankings/free-money', new TopBarInfo('Classements par coins')],
      ['/global-rankings/points', new TopBarInfo('Classements par points')],
      ['/global-rankings/total-prono', new TopBarInfo('Classements par pronos')],
      ['/global-rankings/total-bad-prono', new TopBarInfo('Classements par mauvais pronos')],
      ['/global-rankings/total-good-prono', new TopBarInfo('Classements par bons pronos')],
      ['/sports', new TopBarInfo('Liste des sports')],
      ['/teams', new TopBarInfo('Liste des équipes')],
      ['/competitions', new TopBarInfo('Liste des compétitions')],
      ['/seasons', new TopBarInfo('Liste des saisons')],
      ['/days', new TopBarInfo('Liste des journées')],
      ['/odds', new TopBarInfo('Liste des côtes')],
      ['/faq/new', new TopBarInfo('Ajouter une FAQ')],
      ['/faq', new TopBarInfo('Liste des FAQ')],
      ['/faq/edit', new TopBarInfo('Modification de la FAQ')],
      ['/settings/experience', new TopBarInfo('Paramètres Expérience')],
      ['/settings/money', new TopBarInfo('Paramètres Monnaie')],
      ['/settings/levels', new TopBarInfo('Paramètres Niveaux')],
      ['/settings/infos', new TopBarInfo('Paramètres Informations')],
      ['/tools', new TopBarInfo('Paramètres Corrections')],
      ['/tools/analyse-duplicate-user-prono', new TopBarInfo('Analyse des "user prono" dupliqués')]
    ]);
  }

  /**
   * Method called when the view is resized
   * @param event
   * @private
   */
  @HostListener('window:resize', ['$event'])
  private onResize(event: any) {
    this.detectScreenSize(event.target.innerWidth);
  }

  /**
   * Method call to set the appropriate title and icon depending of the content page.
   */
  private setTopBarInfos(currentRoute: string): void {
    this.topBarInfos = this.topBarInfosMap.get(currentRoute);
  }

  /**
   * Method called to detect the appropriate view between mobile and desktop based on screen width
   * @param width
   * @private
   */
  private detectScreenSize(width: number): void {
    if (width <= 768) {
      this.isMobile = true;
      this.isCollapsed = true;
    } else {
      this.isMobile = false;
      this.isCollapsed = false;
    }
  }

  /**
   * Method called to check the validity of the token at the start of the application. If token expire in less than 48 hours, renew it
   * @private
   */
  private validTokenBeforeStartApp() {
    this.authService
      .checkToken()
      .then(tokenVerified => {
        // Calculate the time remaining before expiration
        const remainingTime = tokenVerified.exp - (new Date().getTime() / 1000);
        // Refresh token in 48h => 172800s
        if (remainingTime < 172800) {
          this.authService
            .refreshToken()
            .then(tokenRefreshed => {
              this.tokenStorageService.saveToken(tokenRefreshed.token);
              this.toastService.success('Session renouvelée avec succès');
            });
        }

        this.streamEventHandlerService.initStreamEventHandler();
      })
      .catch(err => {
        console.error(err);
        this.toastService.error('Une erreur s\'est produite');
      });
  }
}
