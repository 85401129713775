<div class="row generic-chart">

  <div class="col">
    <canvas baseChart [width]="widthCanvas" [height]="heightCanvas"
            [datasets]="chartDataConfiguration.data.datasets"
            [options]="chartDataConfiguration.options"
            [labels]="chartDataConfiguration.data.labels"
            [type]="chartDataConfiguration.type">
    </canvas>
  </div>

</div>
