import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable} from 'rxjs';
import {TokenStorageService} from "../tokenStorage/token-storage.service";

@Injectable({
  providedIn: 'root'
})
export class AuthGuard  {

  constructor(private tokenStorageService: TokenStorageService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
    if (this.tokenStorageService.token.getValue()) {

      if(route.url.length > 0 && route.url[0].path === 'login') {
        return this.router.navigateByUrl('');
      }

      return true;
    }
    else if(route.url.length > 0 && route.url[0].path === 'login') {
      return true;
    }

    return this.router.navigateByUrl('/login');
  }
}
