import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'thousandSeparator',
})
export class ThousandSeparatorPipe implements PipeTransform {

  transform(value: string | number, ...args: any[]): string {
    if (value === undefined) {
      return '';
    }
    let n = value;
    if (typeof value === 'string') {
      n = parseInt(value);
    }

    const rx = /(\d+)(\d{3})/;
    return String(n).replace(/^\d+/, function (w) {
      let res = w;
      while (rx.test(res)) {
        res = res.replace(rx, '$1 $2');
      }
      return res;
    });
  }
}
