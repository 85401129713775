export interface Notification<T> {
  id: string,
  date: string,
  code: Notification.Code,
  body?: T
}

export namespace Notification {
  export interface Observer<T> {
    observe (notification: Notification<any>): Promise<void>
    shouldObserve (notification: Notification<any>): boolean
  }

  export enum Code {
    NEW_ITEM = 'NEW_ITEM',
    ITEM_UPDATED = 'ITEM_UPDATED',
    REFRESH_ALL_ITEM = 'REFRESH_ALL_ITEM',
    NEW_ITEM_CATEGORY = 'NEW_ITEM_CATEGORY',
    ITEM_CATEGORY_UPDATED = 'ITEM_CATEGORY_UPDATED',
    REFRESH_ALL_ITEM_CATEGORY = 'REFRESH_ALL_ITEM_CATEGORY'
  }
}
