import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';
import {HttpClient, HttpParams} from '@angular/common/http';
import {map, tap} from 'rxjs/operators';
import {QueryOptionsBuilder} from '../../../models/queryOptions/builder';
import {QueryOptionsSort} from '../../../models/queryOptions/sort';
import {firstValueFrom} from 'rxjs';

interface IGenericModel {
  toJSON(): any;
}

@Injectable({
  providedIn: 'root'
})
export abstract class BaseService<T extends IGenericModel> {

  apiUrl: string;
  baseUrl!: string;
  sortField!: string;

  constructor(protected http: HttpClient) {
    if (environment.api.isRemote) {
      this.apiUrl = environment.api.remote;
    } else {
      this.apiUrl = environment.api.local;
    }
  }

  /**
   * Method called for retrieve all items from the type selected
   * @param mapFromJSON
   * @param queryOptions
   */
  findAll(queryOptions?: QueryOptionsBuilder, mapFromJSON: boolean = true): Promise<T[] | any[]> {

    if (!queryOptions) {
      queryOptions = new QueryOptionsBuilder();
    }

    if (queryOptions.sort.length === 0) {
      queryOptions.pushSort(new QueryOptionsSort(this.sortField));
    }

    const params = new HttpParams()
      .append('options', queryOptions.toJSON());

    let query = this.http
      .get<T[]>(this.baseUrl, {params});

    if (mapFromJSON) {
      query = query
        .pipe(
          map(res => res.map(i => this.typeFromJSON(i)))
        );
    }

    return firstValueFrom(query)
  }

  /**
   * Method called for retrieve an item from the type selected by the given id
   * @param id
   * @param queryOptions
   * @param mapFromJSON
   */
  findById(id: string, queryOptions?: QueryOptionsBuilder, mapFromJSON: boolean = true): Promise<T> {

    let params = {};

    if (queryOptions) {
      // @ts-ignore
      params = new HttpParams().append('options', queryOptions.toJSON());
    }

    let query = this.http
      .get<T>(this.baseUrl + id, {params});

    if (mapFromJSON) {
      query = query
        .pipe(
          map(res => this.typeFromJSON(res))
        );
    }

    return firstValueFrom(query)
  }

  /**
   * Method called for retrieve an item from the type selected by the given key/value
   * @param queryOptions
   * @param mapFromJSON
   */
  findOneByAttr(queryOptions?: QueryOptionsBuilder, mapFromJSON: boolean = true): Promise<T> {

    let params = {};

    if (queryOptions) {
      // @ts-ignore
      params = new HttpParams().append('options', queryOptions.toJSON());
    }

    let query = this.http
      .get<T>(this.baseUrl + 'byAttr', {params});

    if (mapFromJSON) {
      query = query
        .pipe(
          map(res => this.typeFromJSON(res))
        );
    }

    return firstValueFrom(query)
  }

  /**
   * Method called for count item for the type selected
   * @param queryOptions
   */
  countBy(queryOptions?: QueryOptionsBuilder): Promise<number> {

    let params = {};

    if (queryOptions) {
      // @ts-ignore
      params = new HttpParams().append('options', queryOptions.toJSON());
    }

    return firstValueFrom(this.http
      .get<number>(this.baseUrl + 'countBy', {params})
      )
  }

  /**
   * Method called for save an item for the type selected
   * @param entityToAdd
   * @param queryOptions
   * @param mapFromJSON
   */
  save(entityToAdd: T, queryOptions?: QueryOptionsBuilder, mapFromJSON: boolean = true): Promise<T> {

    let params = {};

    if (queryOptions) {
      // @ts-ignore
      params = new HttpParams().append('options', queryOptions.toJSON());
    }

    let query = this.http
      .post<T>(this.baseUrl, entityToAdd.toJSON(), {params})

    if (mapFromJSON) {
      query = query
        .pipe(
          map(res => this.typeFromJSON(res))
        );
    }

    return firstValueFrom(query)
  }

  /**
   * Method called for update an item for the type selected
   * @param entityToUpdate
   * @param queryOptions
   * @param mapFromJSON
   */
  update(entityToUpdate: T, queryOptions?: QueryOptionsBuilder,  mapFromJSON: boolean = true): Promise<T> {

    let params = {};

    if (queryOptions) {
      // @ts-ignore
      params = new HttpParams().append('options', queryOptions.toJSON());
    }

    let query = this.http
      .patch<T>(this.baseUrl, entityToUpdate.toJSON(), {params});

    if (mapFromJSON) {
      query = query
        .pipe(
          map(res => this.typeFromJSON(res))
        );
    }

    return firstValueFrom(query)
  }

  /**
   * Method called to update an existing item but it don't update each row, it overwrite the entire document.
   * So make sure that the object is complete and correct.
   * @param entityToUpdate
   * @param queryOptions
   * @param mapFromJSON
   */
  updatePut(entityToUpdate: T, queryOptions?: QueryOptionsBuilder, mapFromJSON: boolean = true): Promise<T> {

    let params = {};

    if (queryOptions) {
      // @ts-ignore
      params = new HttpParams().append('options', queryOptions.toJSON());
    }

    let query = this.http
      .put<T>(this.baseUrl, entityToUpdate.toJSON(), {params});

    if (mapFromJSON) {
      query = query
        .pipe(
          map(res => this.typeFromJSON(res))
        );
    }

    return firstValueFrom(query)
  }

  /**
   * Method called for delete an item for the type selected by the given id
   * @param entityIdToDelete
   */
  delete(entityIdToDelete: string | undefined): Promise<T> {

    return firstValueFrom(this.http
      .delete<T>(this.baseUrl + entityIdToDelete)
      .pipe(
        tap(res => {
          if (!res) {
            throw new Error('Erreur lors de la suppression. Consulter l\'API');
          }
        }),
        map(res => this.typeFromJSON(res))
      ))
  }

  /**
   * Method called for delete all items that match the filter
   * @param queryOptions
   */
  deleteMany(queryOptions: QueryOptionsBuilder): Promise<T> {

    const params = new HttpParams().append('options', queryOptions.toJSON());

    return firstValueFrom(this.http
      .delete<T>(this.baseUrl, {params})
      )
  }

  /**
   * Abstract method to be implement in inherit class in order to type the class
   * @param dataAsJSON
   */
  abstract typeFromJSON(dataAsJSON: any): T;

}
