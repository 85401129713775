export class QueryOptionsSort {

  private _sort: string;

  constructor(sortList: string) {
    this._sort = sortList;
  }

  get sort(): string {
    return this._sort;
  }

  set sort(value: string) {
    this._sort = value;
  }

  /**
   * Method called for transform the object instance to a JSON object
   */
  toJSON(): any {
    return this.sort;
  }

}
