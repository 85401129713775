import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AuthenticationRoutingModule } from './authentication-routing.module';
import { AuthenticationComponent } from './views/authentication.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {FormLoginComponent} from "./components/form-login/form-login.component";
import {SharedModule} from "../shared/shared.module";



@NgModule({
  declarations: [
    AuthenticationComponent,
    FormLoginComponent,

  ],
  exports: [
  ],
  imports: [
    CommonModule,
    AuthenticationRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule,
  ]
})
export class AuthenticationModule { }
