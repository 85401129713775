import {Level} from './level';

interface ISettings {
  pushNotifications: boolean;
  appRate: {
    refusedRate: boolean,
    alreadyRate: boolean
  };
}

interface ITicketsAds {
  history: any,
  timestamps: string[]
}

export class User {

  private _id: string | undefined;
  private _username: string;
  private _email: string;
  private _password: string;
  private _appVersion: string;
  private _deviceType: string;
  private _groups: any;
  private _settings: ISettings;
  private _city: string;
  private _sexe: string;
  private _freeMoney: number;
  private _paidMoney: number;
  private _currentLevel: string | Level; // = levelId
  private _totalXp: number;
  private _ticketsAds: ITicketsAds;
  private _createdAt: string;
  private _updatedAt: string;
  private _needClearCache: boolean;
  private _needCheckNews: boolean;

  private _commentsNb: number | undefined;
  private _pronosNb: number | undefined;
  private _place: number | undefined;

  constructor(username: string, email: string, password: string, appVersion: string, deviceType: string, groups: any, settings: ISettings, city: string, sexe: string, freeMoney: number, paidMoney: number, currentLevel: string | Level, totalXp: number, ticketsAds: ITicketsAds, createdAt: string, updatedAt: string, needClearCache: boolean, needCheckNews: boolean, id?: string) {
    if (typeof id === 'string') {
      this._id = id;
    }
    this._username = username;
    this._email = email;
    this._password = password;
    this._appVersion = appVersion;
    this._deviceType = deviceType;
    this._groups = groups;
    this._settings = settings;
    this._city = city;
    this._sexe = sexe;
    this._freeMoney = freeMoney;
    this._paidMoney = paidMoney;
    this._currentLevel = currentLevel;
    this._totalXp = totalXp;
    this._ticketsAds = ticketsAds;
    this._createdAt = createdAt;
    this._updatedAt = updatedAt;
    this._needClearCache = needClearCache;
    this._needCheckNews = needCheckNews;
  }

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get username(): string {
    return this._username;
  }

  set username(value: string) {
    this._username = value;
  }

  get email(): string {
    return this._email;
  }

  set email(value: string) {
    this._email = value;
  }

  get password(): string {
    return this._password;
  }

  set password(value: string) {
    this._password = value;
  }

  get appVersion(): string {
    return this._appVersion;
  }

  set appVersion(value: string) {
    this._appVersion = value;
  }

  get deviceType(): string {
    return this._deviceType;
  }

  set deviceType(value: string) {
    this._deviceType = value;
  }

  get groups(): any {
    return this._groups;
  }

  set groups(value: any) {
    this._groups = value;
  }

  get settings(): ISettings {
    return this._settings;
  }

  set settings(value: ISettings) {
    this._settings = value;
  }

  get city(): string {
    return this._city;
  }

  set city(value: string) {
    this._city = value;
  }

  get sexe(): string {
    return this._sexe;
  }

  set sexe(value: string) {
    this._sexe = value;
  }

  get freeMoney(): number {
    return this._freeMoney;
  }

  set freeMoney(value: number) {
    this._freeMoney = value;
  }

  get paidMoney(): number {
    return this._paidMoney;
  }

  set paidMoney(value: number) {
    this._paidMoney = value;
  }

  get currentLevel(): string | Level {
    return this._currentLevel;
  }

  set currentLevel(value: string | Level) {
    this._currentLevel = value;
  }

  get totalXp(): number {
    return this._totalXp;
  }

  set totalXp(value: number) {
    this._totalXp = value;
  }

  get ticketsAds(): ITicketsAds {
    return this._ticketsAds;
  }

  set ticketsAds(value: ITicketsAds) {
    this._ticketsAds = value;
  }

  get createdAt(): string {
    return this._createdAt;
  }

  set createdAt(value: string) {
    this._createdAt = value;
  }

  get updatedAt(): string {
    return this._updatedAt;
  }

  set updatedAt(value: string) {
    this._updatedAt = value;
  }

  get needClearCache(): boolean {
    return this._needClearCache;
  }

  set needClearCache(value: boolean) {
    this._needClearCache = value;
  }

  get needCheckNews(): boolean {
    return this._needCheckNews;
  }

  set needCheckNews(value: boolean) {
    this._needCheckNews = value;
  }

  get commentsNb(): number | undefined {
    return this._commentsNb;
  }

  set commentsNb(value: number | undefined) {
    this._commentsNb = value;
  }

  get pronosNb(): number | undefined {
    return this._pronosNb;
  }

  set pronosNb(value: number | undefined) {
    this._pronosNb = value;
  }

  get place(): number | undefined {
    return this._place;
  }

  set place(value: number | undefined) {
    this._place = value;
  }

  /**
   * Method for transform the class to json for API
   */
  toJSON(): any {
    return {
      username: this.username,
      email: this.email,
      password: this.password,
      appVersion: this.appVersion,
      deviceType: this.deviceType,
      groups: this.groups,
      settings: this.settings,
      city: this.city,
      sexe: this.sexe,
      freeMoney: this.freeMoney,
      paidMoney: this.paidMoney,
      currentLevel: typeof this.currentLevel === 'string' ? this.currentLevel : this.currentLevel.id,
      totalXp: this.totalXp,
      ticketsAds: this.ticketsAds,
      createdAt: this.createdAt,
      updatedAt: this.updatedAt,
      needClearCache: this.needClearCache,
      needCheckNews: this.needCheckNews,
      _id: this.id
    };
  }

  /**
   * Method for transform data form API into class
   */
  static fromJSON(dataAsJSON: any): User {
    const user = new User(
      dataAsJSON.username,
      dataAsJSON.email,
      dataAsJSON.password,
      dataAsJSON.appVersion,
      dataAsJSON.deviceType,
      dataAsJSON.groups,
      dataAsJSON.settings,
      dataAsJSON.city,
      dataAsJSON.sexe,
      dataAsJSON.freeMoney,
      dataAsJSON.paidMoney,
      typeof dataAsJSON.currentLevel === 'string' ? dataAsJSON.currentLevel : Level.fromJSON(dataAsJSON.currentLevel),
      dataAsJSON.totalXp,
      dataAsJSON.ticketsAds,
      dataAsJSON.createdAt,
      dataAsJSON.updatedAt,
      dataAsJSON.needClearCache,
      dataAsJSON.needCheckNews,
      dataAsJSON._id
    );

    if (dataAsJSON.commentsNb) {
      user.commentsNb = dataAsJSON.commentsNb;
    }

    if (dataAsJSON.pronosNb) {
      user.pronosNb = dataAsJSON.pronosNb;
    }

    return user;
  }
}
