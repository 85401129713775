import {Component, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from '@angular/forms';

import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';

@Component({
  selector: 'app-form-login',
  templateUrl: './form-login.component.html',
  styleUrls: ['./form-login.component.scss']
})
export class FormLoginComponent implements OnInit {

  loginForm!: UntypedFormGroup;

  error!: string;

  constructor(private authService: AuthService, private router: Router,
              private fb: UntypedFormBuilder) {
  }

  ngOnInit(): void {
    this._initForm();
  }

  /**
   * Method called when the user try to login
   */
  onClickLoginBtn(): void {

    // check if we have errors before try to send all the informations
    if (this.loginForm.valid) {

      this.error = '';

      this.authService
        .login(this.loginForm.value)
        .then(() => {
          this.router.navigateByUrl('/');
        })
        .catch((err: any) => {
          this.error = err.errorMsg;
        });

    }

  }

  /**
   * Method called for init all the forms
   * @private
   */
  private _initForm(): void {

    this.loginForm = this.fb.group({
      username: [null, [Validators.required]],
      password: [null, [Validators.required]]
    });

  }

}
