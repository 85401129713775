export class QueryOptionsPage {

  private _page: number;

  constructor(page: number) {
    this._page = page;
  }

  get page(): number {
    return this._page;
  }

  set page(value: number) {
    this._page = value;
  }

  /**
   * Method called for transform the object instance to a JSON object
   */
  toJSON(): number {
    return this.page;
  }

}
