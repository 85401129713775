import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {Comment} from '../../../../models/comment';
import {CommentService} from '../../services/comment/comment.service';
import {ToastService} from 'angular-toastify';
import {NgbActiveModal, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ConfirmDeleteComponent} from '../confirm-delete/confirm-delete.component';

@Component({
  selector: 'app-comments-list',
  templateUrl: './comments-list.component.html',
  styleUrls: ['./comments-list.component.scss']
})
export class CommentsListComponent implements OnInit {

  @Input() searchId!: string;

  @Output() commentDeleted: EventEmitter<Comment>;

  comments: Comment[];

  page: number;

  constructor(private commentService: CommentService, private toastService: ToastService,
              public activeModal: NgbActiveModal, private modalService: NgbModal) {
    this.page = 1;
    this.comments = [];
    this.commentDeleted = new EventEmitter<Comment>();
  }

  /**
   * Lifecycle Method
   */
  ngOnInit(): void {
    this.loadComments();
  }

  /**
   * Method called to popup ask confirmation to delete.
   */
  onClickOpenModalConfirmDelete(i: number): void {
    const addModal = this.modalService.open(ConfirmDeleteComponent, {centered: true});

    addModal.componentInstance.title = 'Suppression d\'un commentaire';
    addModal.componentInstance.objetType = 'ce commentaire';
    addModal.componentInstance.objectToDelete = this.comments[i].content;

    addModal.componentInstance.confirmation.subscribe((res: boolean) => {
      if (res) {
        this.deleteComment(i);
      }
    });
  }

  /**
   * Method called for call the API in order to load more data
   */
  onScroll() {
    this.page++;
    this.loadComments();
  }

  /**
   * Method for call the service and load comments
   * @private
   */
  private loadComments() {
    this.commentService
      .findAllByFilters(this.searchId, this.page)
      .then((comments: Comment[]) => {
        this.comments = this.comments.concat(comments);
      });
  }

  /**
   * Method called when the user want to delete a comment
   * @param i
   */
  private deleteComment(i: number) {
    this.commentService.delete(this.comments[i].id)
      .then(() => {
        this.comments.splice(i, 1);
        this.commentDeleted.emit(this.comments[i]);
        this.toastService.success('Commentaire supprimé avec succès.');
      })
      .catch(
        (err) => {
          console.error(err);
          this.toastService.error('Une erreur s\'est produite.');
        });
  }

}
