interface ItemCategoryHttp {
  _id?: string;
  name: string;
  storeIdentifier: ItemCategory.StoreIdentifier;
  imgDirName: string;
  itemsNb?: number;
  isVisible: boolean;
  priorityOrder: number;
  createdAt: Date;
}

export interface ItemCategoryHttpIn extends ItemCategoryHttp {
  categoryId?: ItemCategoryHttpIn;
}

export interface ItemCategoryHttpOut extends ItemCategoryHttp {
  categoryId?: string;
}

export interface ItemCategory {
  id?: string;
  name: string;
  storeIdentifier: ItemCategory.StoreIdentifier;
  imgDirName: string;
  isVisible: boolean;
  category?: ItemCategory;
  priorityOrder: number;
  createdAt: Date;
  itemsNb?: number;
}

export namespace ItemCategory {
  export enum StoreIdentifier {
    PRONOS = 'pronos',
    ACTIONS = 'actions',
    CUSTOMISATION = 'customisation',
    COMPLETE_OUTFITS = 'complete-outfits',
    SETS = 'sets'
  }

  export function blankItemCategory(): ItemCategory {
    return {
      id: undefined,
      name: '',
      storeIdentifier: '' as ItemCategory.StoreIdentifier,
      imgDirName: '',
      isVisible: false,
      priorityOrder: 0,
      category: undefined,
      createdAt: new Date(),
      itemsNb: undefined
    };
  }

  export function fromItemCategoryHttpToItemCategory(itemCategoryHttp: ItemCategoryHttpIn): ItemCategory {
    return {
      id: itemCategoryHttp._id,
      name: itemCategoryHttp.name,
      storeIdentifier: itemCategoryHttp.storeIdentifier,
      imgDirName: itemCategoryHttp.imgDirName,
      category: itemCategoryHttp.categoryId ? ItemCategory.fromItemCategoryHttpToItemCategory(itemCategoryHttp.categoryId) : undefined,
      isVisible: itemCategoryHttp.isVisible,
      priorityOrder: itemCategoryHttp.priorityOrder,
      createdAt: itemCategoryHttp.createdAt,
      itemsNb: itemCategoryHttp.itemsNb
    };
  }

  export function fromItemCategoryToItemCategoryHttp(itemCategory: ItemCategory): ItemCategoryHttpOut {
    return {
      _id: itemCategory.id,
      name: itemCategory.name,
      storeIdentifier: itemCategory.storeIdentifier,
      imgDirName: itemCategory.imgDirName,
      categoryId: itemCategory.category?.id!,
      isVisible: itemCategory.isVisible,
      priorityOrder: itemCategory.priorityOrder,
      createdAt: itemCategory.createdAt
    };
  }
}
