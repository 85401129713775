import {ItemCategory, ItemCategoryHttpIn} from './itemCategory';
import {environment} from '../../environments/environment';

interface ItemHttp {
  _id?: string;
  name: string;
  description: string;
  storeIdentifier: Item.StoreIdentifier;
  imgName: string;
  freeMoneyPrice?: number;
  paidMoneyPrice?: number;
  isLocked: boolean;
  isVisible: boolean;
  genderTarget?: string;
  priorityOrder: number;
  createdAt: Date;
  isDiscount: boolean;
  discountLabel?: string;
  startDiscountDate?: Date;
  endDiscountDate?: Date;
  discountFreeMoneyPrice?: number;
  discountPaidMoneyPrice?: number;
}

export interface ItemHttpIn extends ItemHttp {
  categoryId: ItemCategoryHttpIn;
  subCategoryId: ItemCategoryHttpIn;
}

export interface ItemHttpOut extends ItemHttp{
  categoryId: string;
  subCategoryId: string;
}

export interface Item {
  id?: string;
  name: string;
  description: string;
  storeIdentifier: Item.StoreIdentifier;
  imgName: string;
  category: ItemCategory;
  subCategory: ItemCategory;
  freeMoneyPrice?: number;
  paidMoneyPrice?: number;
  isLocked: boolean;
  isVisible: boolean;
  genderTarget?: string;
  priorityOrder: number;
  createdAt: Date;
  isDiscount: boolean;
  discountLabel?: string;
  startDiscountDate?: Date;
  endDiscountDate?: Date;
  discountFreeMoneyPrice?: number;
  discountPaidMoneyPrice?: number;
  fullImgPath?: string;
}

export namespace Item {
  export enum StoreIdentifier {
    SHOW_USERS = 'show-users',
    RESET_PRONO = 'reset-prono',
    COMPLETE_OUTFIT = 'complete-outfit',
    SET = 'set'
  }

  export function fullImgPath(item: Item): string {
    return `${environment.resourcesServer}/store/${item.category.imgDirName}/${item.subCategory!.imgDirName}/${item.imgName}`;
  }

  export function blankItem(): Item {
    return {
      id: undefined,
      name: '',
      description: '',
      storeIdentifier: '' as Item.StoreIdentifier,
      imgName: '',
      category: ItemCategory.blankItemCategory(),
      subCategory: ItemCategory.blankItemCategory(),
      freeMoneyPrice: undefined,
      paidMoneyPrice: undefined,
      isLocked: false,
      isVisible: true,
      genderTarget: '',
      priorityOrder: 0,
      createdAt: new Date(),
      isDiscount: false,
      discountLabel: undefined,
      startDiscountDate: undefined,
      endDiscountDate: undefined,
      discountFreeMoneyPrice: undefined,
      discountPaidMoneyPrice: undefined
    };
  }

  export function fromItemHttpToItem(itemHttp: ItemHttpIn): Item {
    return {
      id: itemHttp._id,
      name: itemHttp.name,
      description: itemHttp.description,
      storeIdentifier: itemHttp.storeIdentifier,
      imgName: itemHttp.imgName,
      category: ItemCategory.fromItemCategoryHttpToItemCategory(itemHttp.categoryId),
      subCategory: ItemCategory.fromItemCategoryHttpToItemCategory(itemHttp.subCategoryId),
      freeMoneyPrice: itemHttp.freeMoneyPrice,
      paidMoneyPrice: itemHttp.paidMoneyPrice,
      isLocked: itemHttp.isLocked,
      isVisible: itemHttp.isVisible,
      genderTarget: itemHttp.genderTarget,
      priorityOrder: itemHttp.priorityOrder,
      createdAt: new Date(itemHttp.createdAt),
      isDiscount: itemHttp.isDiscount,
      discountLabel: itemHttp.discountLabel,
      startDiscountDate: itemHttp.startDiscountDate,
      endDiscountDate: itemHttp.endDiscountDate,
      discountFreeMoneyPrice: itemHttp.discountFreeMoneyPrice,
      discountPaidMoneyPrice: itemHttp.discountPaidMoneyPrice
    };
  }

  export function fromItemToItemHttp(item: Item): ItemHttpOut {
    return {
      _id: item.id,
      name: item.name,
      description: item.description,
      storeIdentifier: item.storeIdentifier,
      imgName: item.imgName,
      categoryId: item.category.id!,
      subCategoryId: item.subCategory.id!,
      freeMoneyPrice: item.freeMoneyPrice,
      paidMoneyPrice: item.paidMoneyPrice,
      isLocked: item.isLocked,
      isVisible: item.isVisible,
      genderTarget: item.genderTarget,
      priorityOrder: item.priorityOrder,
      createdAt: item.createdAt,
      isDiscount: item.isDiscount,
      discountLabel: item.discountLabel,
      startDiscountDate: item.startDiscountDate,
      endDiscountDate: item.endDiscountDate,
      discountFreeMoneyPrice: item.discountFreeMoneyPrice,
      discountPaidMoneyPrice: item.discountPaidMoneyPrice
    };
  }
}
