export class Level {

  private _id: string | undefined;
  private _name: string;
  private _xp: number;

  constructor(name: string, xp: number, id?: string) {
    if (typeof id === 'string') {
      this._id = id;
    }
    this._name = name;
    this._xp = xp;
  }

  get id(): string | undefined {
    return this._id;
  }

  set id(value: string | undefined) {
    this._id = value;
  }

  get name(): string {
    return this._name;
  }

  set name(value: string) {
    this._name = value;
  }

  get xp(): number {
    return this._xp;
  }

  set xp(value: number) {
    this._xp = value;
  }

  /**
   * Method for transform the class to json for API
   */
  toJSON(): any {
    return {
      name: this.name,
      xp: this.xp,
      _id: this.id
    };
  }

  /**
   * Method for transform data form API into class
   */
  static fromJSON(dataAsJSON: any): Level {
    return new Level(
      dataAsJSON.name,
      dataAsJSON.xp,
      dataAsJSON._id
    );
  }

}
