import {Injectable} from '@angular/core';
import {StreamEventApi} from '../../api/stream-event/stream-event.api';
import {ToastService} from 'angular-toastify';
import {ObserveNotificationService} from '../../notifications/observe-notification/observe-notification.service';

@Injectable({
  providedIn: 'root'
})
export class StreamEventHandlerService {

  private streamEventSource!: EventSource;

  constructor(private streamEventApi: StreamEventApi, private toastService: ToastService,
              private observeNotificationService: ObserveNotificationService) {
  }

  initStreamEventHandler() {
    this.streamEventSource = this.streamEventApi.getSSEStream();
    this.streamEventSource.onopen = () => console.info('Connexion ouverte pour le stream SSE.');
    this.streamEventSource.onmessage = (event) => this.handleStreamEvent(event);
    this.streamEventSource.onerror = (error) => this.handleStreamError(error);
  }

  closeConnection(): void {
    this.streamEventSource.close();
    if(this.streamEventSource.readyState !== EventSource.CLOSED) {
      this.toastService.error('Une erreur est survenue durant la fermeture de la connection SSE.')
    }
    else {
      console.info('Connexion fermée pour le stream SSE.')
    }
  }

  private handleStreamEvent(event: any): void {
    const notification = JSON.parse(event.data)
    this.observeNotificationService.observe(notification)
  }

  private handleStreamError(error: any): void {
    console.error(error);
  }
}
