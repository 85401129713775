<div class="m-0 container confirm-delete">

  <button (click)="activeModal.dismiss('Cross click')" class="btn-close-popup">
    <img class="btn-icon" src="assets/img/menu-icons/close.svg" alt="close">
  </button>

  <div class="row my-3 page-header">
    <div class="d-none d-md-flex">
      <img class="mt-1 me-3 icons" src="assets/img/menu-icons/close.svg" alt="dashboard">
      <h1 class="m-0">{{title}}</h1>
    </div>
  </div>

  <div class="mb-3">
    <p><strong>Etes-vous sûr de vouloir supprimer {{objetType}} : <span class="text-primary">{{objectToDelete}}</span></strong></p>
    <p>Toutes les informations associées à {{objetType}} seront définitivement supprimées.
      <br><br>
      <span class="text-danger">Cette opération ne peut pas être annulée.</span>
    </p>
  </div>

  <div class="col">
    <button type="button" ngbAutofocus class="btn btn-danger" (click)="confirm(true)">Oui, je suis sûr !</button>
    <button type="button" class="btn btn-link" (click)="confirm(false)">Annuler</button>
  </div>

</div>
