import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {
  ItemCategory,
  ItemCategoryHttpIn, ItemCategoryHttpOut
} from '../../../../models/itemCategory';
import {QueryOptionsBuilder} from '../../../../models/queryOptions/builder';
import {BaseApi} from '../../../shared/services/base.api';
import {QueryOptionsPopulate} from '../../../../models/queryOptions/populate';

@Injectable({
  providedIn: 'root'
})
export class ItemCategoryApi extends BaseApi<ItemCategory, ItemCategoryHttpIn, ItemCategoryHttpOut> {

  constructor(http: HttpClient) {
    super(http);
    this.baseUrl = this.apiUrl + 'item-categories/';
    this.sortField = 'name';
  }

  typeFromJSON(dataAsJSON: any): ItemCategory {
    return ItemCategory.fromItemCategoryHttpToItemCategory(dataAsJSON);
  };

  typeToJSON(entity: ItemCategory): ItemCategoryHttpOut {
    return ItemCategory.fromItemCategoryToItemCategoryHttp(entity);
  }

  findByIdWithPopulate(itemCategoryId: string): Promise<ItemCategory> {
    const queryOptions = new QueryOptionsBuilder();
    queryOptions.pushPopulate(new QueryOptionsPopulate('categoryId'))
    return this.findById(itemCategoryId, queryOptions);
  }

  findAllWithPopulate(): Promise<ItemCategory[]> {
    const queryOptions = new QueryOptionsBuilder();
    queryOptions.pushPopulate(new QueryOptionsPopulate('categoryId'))
    return this.findAll(queryOptions);
  }

}
