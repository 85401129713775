import {Injectable} from '@angular/core';
import {ItemStore} from './item.store';
import {ItemApi} from '../../api/item/item.api';
import {ItemQuery} from './item.query';
import {Item} from '../../../../models/item';
import {ToastService} from 'angular-toastify';
import {Router} from '@angular/router';

@Injectable({providedIn: 'root'})
export class ItemService {

  constructor(private itemStore: ItemStore, private itemApi: ItemApi,
              private itemQuery: ItemQuery, private toastService: ToastService,
              private router: Router) {
  }

  async fetchById(itemId: string): Promise<void> {
    const item = await this.itemApi.findByIdWithPopulate(itemId);
    this.itemStore.upsert(item.id!, item);
  }

  async fetchAll(): Promise<void> {
    const items = await this.itemApi.findAllWithPopulate();
    this.itemStore.upsertMany(items);
  }

  async save(itemToSave: Item): Promise<void> {
    try {
      await this.itemApi.save(itemToSave);
      this.router.navigateByUrl('/shop');
      this.toastService.success('Produit ajouté.');
    } catch (err) {
      console.error(err);
      this.toastService.error('Une erreur s\'est produite.');
    }
  }

  async updatePut(itemEdited: Item): Promise<void> {
    try {
      await this.itemApi.updatePut(itemEdited);
      this.router.navigateByUrl('/shop');
      this.toastService.success('Produit modifié.');
    } catch (err) {
      console.error(err);
      this.toastService.error('Une erreur s\'est produite.');
    }
  }

  async updateItemsCatIdBySubCatId(itemSubCategoryId: string, itemCategoryId: string): Promise<void> {
    try {
      await this.itemApi.updateItemsCatIdBySubCatId(itemSubCategoryId, itemCategoryId);
      this.toastService.success('Items mis à jour.');
    } catch (err) {
      console.error(err);
      this.toastService.error('Une erreur s\'est produite.');
    }
  }

  updateFilters(searchTerms?: string, categoryId?: string, subCategoryId?: string): void {
    this.itemQuery.filters.next({searchTerms, categoryId, subCategoryId});
  }

}
