import {Pipe, PipeTransform} from "@angular/core";

@Pipe({
  name:'emojisCleaner'
})

export class EmojisCleanerPipe implements PipeTransform{

  transform(stringToTransform: string): string{
    if (stringToTransform) {

      // Remove some emojis (not all).
      return stringToTransform.replace(/([\uE000-\uF8FF]|\uD83C[\uDF00-\uDFFF]|\uD83D[\uDC00-\uDDFF])/g, '').trim();
    }
    return '';
  }
}



