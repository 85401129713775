export class QueryOptionsLimit {

  private _limit: number;

  constructor(limit: number) {
    this._limit = limit;
  }

  get limit(): number {
    return this._limit;
  }

  set limit(value: number) {
    this._limit = value;
  }

  /**
   * Method called for transform the object instance to a JSON object
   */
  toJSON(): number {
    return this.limit;
  }

}
