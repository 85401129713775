import {Injectable} from '@angular/core';
import {Notification} from '../../../../models/notification/notification';
import {
  ItemCategoryUpdatedNotification,
  NewItemCategoryNotification, RefreshAllItemCategoryNotification
} from '../../../../models/notification/item-category.notification';
import {ItemCategoryService} from '../../../shop/store/itemCategory/item-category.service';

@Injectable({providedIn: 'root'})
export class ItemCategoryNotificationObserver implements Notification.Observer<NewItemCategoryNotification | ItemCategoryUpdatedNotification | RefreshAllItemCategoryNotification> {
  constructor(private itemCategoryService: ItemCategoryService) {
  }

  async observe(notification: Notification<NewItemCategoryNotification | ItemCategoryUpdatedNotification | RefreshAllItemCategoryNotification>): Promise<void> {
    if (notification.code === Notification.Code.NEW_ITEM_CATEGORY || notification.code === Notification.Code.ITEM_CATEGORY_UPDATED) {
      this.itemCategoryService.fetchById((notification as unknown as Notification<NewItemCategoryNotification | ItemCategoryUpdatedNotification>).body?.itemCategoryId!);
    }

    if(notification.code === Notification.Code.REFRESH_ALL_ITEM_CATEGORY) {
      this.itemCategoryService.fetchAll()
    }
  }

  shouldObserve(notification: Notification<any>): boolean {
    return notification.code === Notification.Code.NEW_ITEM_CATEGORY
      || notification.code === Notification.Code.ITEM_CATEGORY_UPDATED
      || notification.code === Notification.Code.REFRESH_ALL_ITEM_CATEGORY;
  }
}
