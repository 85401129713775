export class QueryOptionsPopulate {

  private _path: string;
  private _options: { sort: string } | undefined;
  private _populate: QueryOptionsPopulate | QueryOptionsPopulate[] | undefined;

  constructor(path: string, options?: { sort: string }, populate?: QueryOptionsPopulate | QueryOptionsPopulate[]) {
    this._path = path;
    this._options = options;
    this._populate = populate;
  }

  get path(): string {
    return this._path;
  }

  set path(value: string) {
    this._path = value;
  }

  get options(): { sort: string } | undefined {
    return this._options;
  }

  set options(value: { sort: string } | undefined) {
    this._options = value;
  }

  get populate(): QueryOptionsPopulate | QueryOptionsPopulate[] | undefined {
    return this._populate;
  }

  set populate(value: QueryOptionsPopulate | QueryOptionsPopulate[] | undefined) {
    this._populate = value;
  }

  /**
   * Method called for transform the object instance to a JSON object
   */
  toJSON(): any {
    return {
      path: this.path,
      options: this.options,
      populate: Array.isArray(this.populate) ? this.populate.map(populate => populate.toJSON()) : this.populate?.toJSON()
    };
  }

}
