export class TopBarInfo {

  private _label: string;
  private _url: string | undefined;
  private _icon: string | undefined;

  constructor(label: string, url?: string, icon?: string,) {
    this._label = label;
    this._url = url;
    this._icon = icon;
  }

  get label(): string {
    return this._label;
  }

  set label(value: string) {
    this._label = value;
  }

  get url(): string | undefined {
    return this._url;
  }

  set url(value: string | undefined) {
    this._url = value;
  }

  get icon(): string | undefined {
    return this._icon;
  }

  set icon(value: string | undefined) {
    this._icon = value;
  }

}
