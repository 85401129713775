import {NgModule} from '@angular/core';
import {RouterModule, Routes} from '@angular/router';
import {AuthGuard} from './modules/shared/services/guards/auth.guard';
import {ItemsAndItemCategoriesResolver} from './modules/shop/resolvers/Items-and-item-categories.resolver';

const routes: Routes = [
  {
    path: 'login',
    loadChildren: () => import('./modules/authentication/authentication.module').then(m => m.AuthenticationModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'users',
    loadChildren: () => import('./modules/user/user.module').then(m => m.UserModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'news',
    loadChildren: () => import('./modules/news/news.module').then(m => m.NewsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'shop',
    loadChildren: () => import('./modules/shop/shop.module').then(m => m.ShopModule),
    canActivate: [AuthGuard],
    resolve: {
      itemsAndItemCategories: ItemsAndItemCategoriesResolver
    }
  },
  {
    path: 'rankings',
    loadChildren: () => import('./modules/ranking/ranking.module').then(m => m.RankingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'faq',
    loadChildren: () => import('./modules/faq/faq.module').then(m => m.FaqModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./modules/setting/setting.module').then(m => m.SettingModule),
    canActivate: [AuthGuard]
  },
  {
    path: '',
    loadChildren: () => import('./modules/dashboard/dashboard.module').then(m => m.DashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'logs',
    loadChildren: () => import('./modules/log/log.module').then(m => m.LogModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'competitions',
    loadChildren: () => import('./modules/competition/competition.module').then(m => m.CompetitionModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sports',
    loadChildren: () => import('./modules/sport/sport.module').then(m => m.SportModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'odds',
    loadChildren: () => import('./modules/odds/odds.module').then(m => m.OddsModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'days',
    loadChildren: () => import('./modules/day/day.module').then(m => m.DayModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'global-rankings',
    loadChildren: () => import('./modules/global-ranking/global-ranking.module').then(m => m.GlobalRankingModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'pronostics',
    loadChildren: () => import('./modules/pronostic/pronostic.module').then(m => m.PronosticModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'seasons',
    loadChildren: () => import('./modules/season/season.module').then(m => m.SeasonModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'teams',
    loadChildren: () => import('./modules/team/team.module').then(m => m.TeamModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'tools',
    loadChildren: () => import('./modules/tool/tool.module').then(m => m.ToolModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'groups',
    loadChildren: () => import('./modules/group/group.module').then(m => m.GroupModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'error',
    loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule)
  },
  {path: '**', loadChildren: () => import('./modules/error/error.module').then(m => m.ErrorModule)},
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule {
}
