import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule} from '@angular/common/http';
import {AngularToastifyModule, ToastService} from 'angular-toastify';
import {CoreModule} from './modules/core/core.module';
import {SharedModule} from './modules/shared/shared.module';
import {AuthenticationModule} from './modules/authentication/authentication.module';
import {SideMenuComponent} from './modules/core/components/side-menu/side-menu.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {registerLocaleData} from '@angular/common';
import localeFr from '@angular/common/locales/fr';
import {NgxSpinnerModule} from 'ngx-spinner';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgChartsModule} from 'ng2-charts';
import {AkitaNgDevtools} from '@datorama/akita-ngdevtools';
import {environment} from '../environments/environment';
import {NotificationObserverInjectionToken} from './injection.token';
import {
  ItemNotificationObserver
} from './modules/shared/notifications/item-notification/item-notification.observer';
import {
  ItemCategoryNotificationObserver
} from './modules/shared/notifications/item-category-notification/item-category-notification.observer';

registerLocaleData(localeFr, 'fr');

@NgModule({
  declarations: [
    AppComponent,
    SideMenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    AngularToastifyModule,
    CoreModule,
    SharedModule.forRoot(),
    AuthenticationModule,
    NgbModule,
    BrowserAnimationsModule,
    NgxSpinnerModule.forRoot({ type: 'ball-scale-multiple' }),
    NgChartsModule,
    environment.production ? [] : AkitaNgDevtools.forRoot(),
  ],
  providers: [
    ToastService,
    {
      provide: NotificationObserverInjectionToken,
      useClass: ItemNotificationObserver,
      multi: true
    },
    {
      provide: NotificationObserverInjectionToken,
      useClass: ItemCategoryNotificationObserver,
      multi: true
    }
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {
}
