import {Injectable} from '@angular/core';
import { RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {ItemCategoryService} from '../store/itemCategory/item-category.service';
import {ItemService} from '../store/item/item.service';

@Injectable({
  providedIn: 'root'
})
export class ItemsAndItemCategoriesResolver  {

  private isAlreadyFetch = false;

  constructor(private itemService: ItemService, private itemCategoryService: ItemCategoryService) {
  }

  async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<void> {
    if (!this.isAlreadyFetch) {
      await this.itemService.fetchAll();
      await this.itemCategoryService.fetchAll();
      this.isAlreadyFetch = true;
    }
  }

}
