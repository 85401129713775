export class QueryOptionsFilter {

  private _key: string | undefined;
  private _value: string | Date | number | boolean | QueryOptionsFilter | Array<any> | undefined;
  private _regex: boolean | undefined;
  private _castToMongo: boolean | undefined;
  private _castToDate: boolean | undefined;
  private _complex: string | undefined;
  private _filter: QueryOptionsFilter[] | undefined;

  constructor(value?: string | Date | number | boolean | QueryOptionsFilter | Array<any> | undefined, key?: string, regex?: boolean, castToMongo?: boolean, castToDate?: boolean, complex?: string, filter?: QueryOptionsFilter[]) {
    this._key = key;
    this._value = value;
    this._regex = regex;
    this._castToMongo = castToMongo;
    this._castToDate = castToDate;
    this._complex = complex;
    this._filter = filter;
  }

  get key(): string | undefined {
    return this._key;
  }

  set key(value: string | undefined) {
    this._key = value;
  }

  get value(): string | Date | number | boolean | QueryOptionsFilter | Array<any> | undefined {
    return this._value;
  }

  set value(value: string | Date | number | boolean | QueryOptionsFilter | Array<any> | undefined) {
    this._value = value;
  }

  get regex(): boolean | undefined {
    return this._regex;
  }

  set regex(value: boolean | undefined) {
    this._regex = value;
  }

  get castToMongo(): boolean | undefined {
    return this._castToMongo;
  }

  set castToMongo(value: boolean | undefined) {
    this._castToMongo = value;
  }

  get castToDate(): boolean | undefined {
    return this._castToDate;
  }

  set castToDate(value: boolean | undefined) {
    this._castToDate = value;
  }

  get complex(): string | undefined {
    return this._complex;
  }

  set complex(value: string | undefined) {
    this._complex = value;
  }

  get filter(): QueryOptionsFilter[] | undefined {
    return this._filter;
  }

  set filter(value: QueryOptionsFilter[] | undefined) {
    this._filter = value;
  }

  /**
   * Method for quickly generate a complex range filter gte and lte
   * @param gteValue
   * @param lteValue
   * @param field
   * @param castToDate
   */
  static generateRangeFilter(gteValue: any, lteValue: any, field: string, castToDate: boolean = false): QueryOptionsFilter {
    const filters: QueryOptionsFilter[] = [];

    // { field: { $gte: gteValue } }
    const gteFilter = new QueryOptionsFilter(undefined, undefined, undefined, undefined,
      undefined, 'gte', [
        new QueryOptionsFilter(gteValue, undefined, undefined, undefined, castToDate)]);
    filters.push(new QueryOptionsFilter(gteFilter, field));

    // { field: { $lte: lteValue } }
    const lteFilter = new QueryOptionsFilter(undefined, undefined, undefined, undefined,
      undefined, 'lte', [
        new QueryOptionsFilter(lteValue, undefined, undefined, undefined, castToDate)]);
    filters.push(new QueryOptionsFilter(lteFilter, field));

    // { $and: [ { field: { $gte: gteValue } }, { field: { $lte: lteValue } } ] }
    return new QueryOptionsFilter(undefined, undefined, undefined, undefined,
      undefined, 'and', filters);
  }

  /**
   * Method called for transform the object instance to a JSON object
   */
  toJSON(): any {
    return {
      key: this.key,
      value: typeof this.value === 'object' && !Array.isArray(this.value) ? this.value.toJSON() : this.value,
      regex: this.regex,
      complex: this.complex,
      castToMongo: this.castToMongo,
      castToDate: this.castToDate,
      filter: this.filter?.map(f=> f.toJSON()),
    }
  }

}
