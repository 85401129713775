import {Injectable} from '@angular/core';
import {environment} from '../../../../../environments/environment';
import {TokenStorageService} from '../../services/tokenStorage/token-storage.service';
// @ts-ignore
import {EventSourcePolyfill} from 'event-source-polyfill';

@Injectable({
  providedIn: 'root'
})
export class StreamEventApi {

  apiUrl: string;
  baseUrl!: string;

  constructor(private tokenStorageService: TokenStorageService) {
    if (environment.api.isRemote) {
      this.apiUrl = environment.api.remote;
    } else {
      this.apiUrl = environment.api.local;
    }
    this.baseUrl = this.apiUrl + 'sse/';
  }

  getSSEStream(): EventSource {
    return new EventSourcePolyfill(this.baseUrl + 'stream-events', {
      headers: { Authorization: this.tokenStorageService.token.getValue() },
      withCredentials: false
    });
  }
}
