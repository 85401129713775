import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../../authentication/services/auth/auth.service';
import {Router} from '@angular/router';
import {NgxSpinnerService} from 'ngx-spinner';
import {ToastService} from 'angular-toastify';

@Component({
  selector: 'app-side-menu',
  templateUrl: './side-menu.component.html',
  styleUrls: ['./side-menu.component.scss']
})
export class SideMenuComponent implements OnInit {

  constructor(private authService: AuthService, public router: Router,
              private spinner: NgxSpinnerService, private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  logout(): void {
    this.spinner.show();
    this.authService
      .logout()
      .then(() => {
        this.router
          .navigateByUrl('/login')
          .then(() => {
            this.toastService.error('Vous avez été déconnecté.');
            this.spinner.hide();
          });
      });
  }
}
