import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-confirm-delete-operation',
  templateUrl: './confirm-delete.component.html',
  styleUrls: ['./confirm-delete.component.scss']
})
export class ConfirmDeleteComponent implements OnInit, OnDestroy {

  @Input() title!: string;
  @Input() objetType!: string;
  @Input() objectToDelete!: string;

  @Output() confirmation: EventEmitter<boolean>;

  constructor(public activeModal: NgbActiveModal) {
    this.confirmation = new EventEmitter<boolean>();
  }

  /**
   * Lifecycle Method
   */
  ngOnInit(): void {
  }

  /**
   * Method called for send choice and closed the modal
   * @param choice
   */
  confirm(choice: boolean) {
    this.confirmation.emit(choice);
    this.activeModal.dismiss(choice);
  }

  /**
   * Lifecycle Method
   */
  ngOnDestroy(): void {
    this.confirmation.complete();
  }

}
