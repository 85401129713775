import {ModuleWithProviders, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SharedRoutingModule} from './shared-routing.module';
import {FormErrorsComponent} from './components/form-errors/form-errors.component';
import {AuthInterceptorService} from './services/interceptor/auth.interceptor';
import {TokenStorageService} from './services/tokenStorage/token-storage.service';
import {UserStorageService} from './services/userStorage/user-storage.service';
import {HTTP_INTERCEPTORS} from '@angular/common/http';
import {CommentsListComponent} from './components/comments-list/comments-list.component';
import {ConfirmDeleteComponent} from './components/confirm-delete/confirm-delete.component';
import {InfiniteScrollModule} from 'ngx-infinite-scroll';
import {GenericChartComponent} from './components/generic-chart/generic-chart.component';
import {NgChartsModule} from 'ng2-charts';
import {EmojisCleanerPipe} from './pipes/emojis-cleaner/emojis-cleaner.pipe';
import {ThousandSeparatorPipe} from './pipes/thousand-separator/thousand-separator';

@NgModule({
  declarations: [
    FormErrorsComponent,
    CommentsListComponent,
    ConfirmDeleteComponent,
    GenericChartComponent,
    EmojisCleanerPipe,
    ThousandSeparatorPipe
  ],
  exports: [
    FormErrorsComponent,
    CommentsListComponent,
    ConfirmDeleteComponent,
    GenericChartComponent,
    EmojisCleanerPipe,
    ThousandSeparatorPipe
  ],
  imports: [
    CommonModule,
    SharedRoutingModule,
    InfiniteScrollModule,
    NgChartsModule
  ]
})
export class SharedModule {

  static forRoot(): ModuleWithProviders<any> {
    return {
      ngModule: SharedModule,
      providers: [
        AuthInterceptorService,
        TokenStorageService,
        UserStorageService,
        {provide: HTTP_INTERCEPTORS, useClass: AuthInterceptorService, multi: true},
      ]
    };
  }

}
