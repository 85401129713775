import {Injectable} from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import {from, NEVER, Observable, Subscription, throwError} from 'rxjs';
import {TokenStorageService} from '../tokenStorage/token-storage.service';
import {NgxSpinnerService} from 'ngx-spinner';
import {catchError, tap} from 'rxjs/operators';
import {Router} from '@angular/router';
import {ToastService} from 'angular-toastify';
import {UserStorageService} from '../userStorage/user-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptorService implements HttpInterceptor {

  private token!: string;
  private tokenSub: Subscription;

  private httpReqPending: number;

  constructor(private tokenStorage: TokenStorageService, private userStorage: UserStorageService,
              private spinner: NgxSpinnerService, private router: Router,
              private toastService: ToastService) {
    this.tokenSub = this.tokenStorage.token
      .subscribe((token: string) => this.token = token);

    this.httpReqPending = 0;
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let toHandle = req;

    if(this.httpReqPending === 0) {
      this.spinner.show();
    }

    if(!req.url.includes('stream-events')) {
      this.httpReqPending++;
    }

    if (!req.url.includes('auth') || req.url.includes('auth/check-token') || req.url.includes('auth/refresh-token')) {
      if (this.token) {
        toHandle = req.clone({
          headers: req.headers.set('Authorization', this.token)
        });
      } else {
        this.tokenStorage.clearToken();
        this.userStorage.clearUser();
        this.router
          .navigateByUrl('/login')
          .then(() => {
            if(this.httpReqPending === 1) {
              this.spinner.hide();
            }
            this.httpReqPending--;
          });
        return from(NEVER);
      }
    }

    return next
      .handle(toHandle)
      .pipe(
        tap(evt => {
          if (evt instanceof HttpResponse) {
            if(this.httpReqPending === 1) {
              this.spinner.hide();
            }
            this.httpReqPending--;
          }
        }),
        catchError(err => {
          if (err instanceof HttpErrorResponse) {
            if (err.status === 401) {
              this.tokenStorage.clearToken();
              this.userStorage.clearUser();
              this.router
                .navigateByUrl('/login')
                .then(() => {
                  this.toastService.error('Vous avez été déconnecté.');
                  if(this.httpReqPending === 1) {
                    this.spinner.hide();
                  }
                  this.httpReqPending--;
                });
            }
          }
          return throwError(err);
        })
      );
  }

  /**
   * Lifecycle method
   */
  ngOnDestroy(): void {
    this.tokenSub.unsubscribe();
  }

}
